.onboardContainer {
    font-family: 'Lato', sans-serif;
    height: 100vh;
}
@supports (height: 100dvh) {
    .onboardContainer {
        height: 100dvh;
    }
}
 
.react-datepicker-wrapper {
    width: 100%;
}

@media (min-width: 640px) {
    .react-datepicker-wrapper {
        width: 50%;
    }
}
.react-datepicker__close-icon::before {
    height: 2.5rem;
 }

.react-datepicker__close-icon::after {
    content: none !important;
 }

@media (min-width: 640px) {
    .scrollableSurveyContent {
        max-height: calc(100vh - 150px);
        overflow-y: auto;
    }

    .specialtiesContainer {
        max-height: calc(100vh - 175px);
    }
}

.cancelBtn:hover {
    background: #f7f7fa;
}

.animatedBtn:disabled {
    opacity: 80%;
}

.animatedBtn:hover {
    background: #5167d2;
}

.animatedBtn .icon {
    position: relative;
    right: 0;
    transition: all ease 0.3s;
}
.animatedBtn:hover .icon {
    right: -5px;
}

.navImg {
    max-width: 96%;
}

.form {
    flex-grow: 1;
}

@media (min-height: 650px) {
    .ratingContainer {
        max-height: 50vh;
    }
    .industryContainer {
        max-height: calc(24rem + 50px);
    }
    .ratings {
        max-height: calc(50vh - 40px);
    }
}

@media (max-height: 650px) and (min-width: 640px) {
    .navigation {
        overflow: visible;
        min-height: 100vh;
    }
}

@media (max-height: 650px), (max-width: 640px) {
    .scrollableSurveyContent, .form {
        max-height: 9999px;
        overflow: visible;
    }

    .screenStep {
        display: none;
    }
}

@media (max-height: 650px) {
    .onboardContainer {
        overflow: scroll;
        min-height: 100vh;
    }
    .formContainer {
        min-height: 100vh;
    }
    .ratingContainer {
        overflow: visible;
    }
    .actionBar {
        margin-top: 10px;
        position: relative;
    }
    .confirmation img {
        max-height: 100px;
    }
}
.react-international-phone-country-selector{position:relative}
.react-international-phone-country-selector-button{display:flex;height:var(--react-international-phone-height, 40px);box-sizing:border-box;align-items:center;justify-content:center;padding:0;border:1px solid var(--react-international-phone-country-selector-border-color, var(--react-international-phone-border-color, gainsboro));margin:0;appearance:button;-webkit-appearance:button;background-color:var(--react-international-phone-country-selector-background-color, var(--react-international-phone-background-color, white));cursor:pointer;text-transform:none;user-select:none}
.react-international-phone-country-selector-button:hover{background-color:var(--react-international-phone-country-selector-background-color-hover, whitesmoke)}
.react-international-phone-country-selector-button--hide-dropdown{cursor:auto}
.react-international-phone-country-selector-button--hide-dropdown:hover{background-color:transparent}
.react-international-phone-country-selector-button__button-content{display:flex;align-items:center;justify-content:center}
.react-international-phone-country-selector-button__flag-emoji{margin:0 4px}
.react-international-phone-country-selector-button__flag-emoji--disabled{opacity:.75}
.react-international-phone-country-selector-button__dropdown-arrow{border-top:var(--react-international-phone-country-selector-arrow-size, 4px) solid var(--react-international-phone-country-selector-arrow-color, #777);border-right:var(--react-international-phone-country-selector-arrow-size, 4px) solid transparent;border-left:var(--react-international-phone-country-selector-arrow-size, 4px) solid transparent;margin-right:4px;transition:all .1s ease-out}
.react-international-phone-country-selector-button__dropdown-arrow--active{transform:rotateX(180deg)}
.react-international-phone-country-selector-button__dropdown-arrow--disabled{border-top-color:var(--react-international-phone-disabled-country-selector-arrow-color, #999)}
.react-international-phone-country-selector-button--disabled{background-color:var(--react-international-phone-disabled-country-selector-background-color, var(--react-international-phone-disabled-background-color, whitesmoke));cursor:auto}
.react-international-phone-country-selector-button--disabled:hover{background-color:var(--react-international-phone-disabled-country-selector-background-color, var(--react-international-phone-disabled-background-color, whitesmoke))}
.react-international-phone-flag-emoji{width:var(--react-international-phone-flag-width, 24px);height:var(--react-international-phone-flag-height, 24px);box-sizing:border-box}
.react-international-phone-country-selector-dropdown{position:absolute;z-index:1;top:var(--react-international-phone-dropdown-left, 44px);left:var(--react-international-phone-dropdown-left, 0);display:flex;width:300px;max-height:200px;flex-direction:column;padding:4px 0;margin:0;background-color:var(--react-international-phone-dropdown-item-background-color, var(--react-international-phone-background-color, white));box-shadow:var(--react-international-phone-dropdown-shadow, 2px 2px 16px rgba(0, 0, 0, .25));color:var(--react-international-phone-dropdown-item-text-color, var(--react-international-phone-text-color, #222));list-style:none;overflow-y:scroll}
.react-international-phone-country-selector-dropdown__list-item{display:flex;min-height:var(--react-international-phone-dropdown-item-height, 28px);box-sizing:border-box;align-items:center;padding:2px 8px}
.react-international-phone-country-selector-dropdown__list-item-flag-emoji{margin-right:8px}
.react-international-phone-country-selector-dropdown__list-item-country-name{margin-right:8px;font-size:var(--react-international-phone-dropdown-item-font-size, 14px)}
.react-international-phone-country-selector-dropdown__list-item-dial-code{color:var(--react-international-phone-dropdown-item-dial-code-color, gray);font-size:var(--react-international-phone-dropdown-item-font-size, 14px)}
.react-international-phone-country-selector-dropdown__list-item:hover{background-color:var(--react-international-phone-selected-dropdown-item-background-color, var(--react-international-phone-selected-dropdown-item-background-color, whitesmoke));cursor:pointer}
.react-international-phone-country-selector-dropdown__list-item--selected,.react-international-phone-country-selector-dropdown__list-item--focused{background-color:var(--react-international-phone-selected-dropdown-item-background-color, whitesmoke);color:var(--react-international-phone-selected-dropdown-item-text-color, var(--react-international-phone-text-color, #222))}
.react-international-phone-country-selector-dropdown__list-item--selected .react-international-phone-country-selector-dropdown__list-item-dial-code,.react-international-phone-country-selector-dropdown__list-item--focused .react-international-phone-country-selector-dropdown__list-item-dial-code{color:var(--react-international-phone-selected-dropdown-item-dial-code-color, var(--react-international-phone-dropdown-item-dial-code-color, gray))}
.react-international-phone-country-selector-dropdown__list-item--focused{background-color:var(--react-international-phone-selected-dropdown-item-background-color, var(--react-international-phone-selected-dropdown-item-background-color, whitesmoke))}
.react-international-phone-dial-code-preview{display:flex;align-items:center;justify-content:center;padding:0 8px;border:1px solid var(--react-international-phone-dial-code-preview-border-color, var(--react-international-phone-border-color, gainsboro));margin-right:-1px;background-color:var(--react-international-phone-dial-code-preview-background-color, var(--react-international-phone-background-color, white));color:var(--react-international-phone-dial-code-preview-text-color, var(--react-international-phone-text-color, #222));font-size:var(--react-international-phone-dial-code-preview-font-size,13px)}
.react-international-phone-dial-code-preview--disabled{background-color:var(--react-international-phone-dial-code-preview-disabled-background-color, var(--react-international-phone-disabled-background-color, whitesmoke));color:var(--react-international-phone-dial-code-preview-disabled-text-color, var(--react-international-phone-disabled-text-color, #666))}
.react-international-phone-input-container{display:flex}
.react-international-phone-input-container .react-international-phone-country-selector-button{border-radius:var(--react-international-phone-border-radius, 4px);margin-right:-1px;border-bottom-right-radius:0;border-top-right-radius:0}
.react-international-phone-input-container .react-international-phone-input{overflow:visible;height:var(--react-international-phone-height, 40px);box-sizing:border-box;padding:0 8px;border:1px solid var(--react-international-phone-border-color, gainsboro);border-radius:var(--react-international-phone-border-radius, 4px);margin:0;background-color:var(--react-international-phone-background-color, white);border-bottom-left-radius:0;border-top-left-radius:0;color:var(--react-international-phone-text-color, #222);font-family:inherit;font-size:13px;width:calc(100% - 36px); font-weight: 700}
.react-international-phone-input-container .react-international-phone-input:focus{outline:none}
.react-international-phone-input-container .react-international-phone-input--disabled{background-color:var(--react-international-phone-disabled-background-color, whitesmoke);color:var(--react-international-phone-disabled-text-color, #666)}

@media (min-width: 640px) {
    .react-international-phone-input-container .react-international-phone-input{font-size:16px};
    .react-international-phone-input-container .react-international-phone-input{font-size:16px};
}